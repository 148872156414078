import React, { useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import { useFormikContext } from "formik";
import CustomSelect from "../formikInputs/CustomSelect";
import { AxiosFetch } from "../AxiosFetch";
import { display } from "@mui/system";
import { useTranslation } from "react-i18next";

function Display(props) {
  const { t } = useTranslation();
  const { data } = props;
  //console.log(data, "data");
  const axiosFetch = AxiosFetch();
  const [groupId, setgroupId] = useState(0);

  //these values are connected to import.jsx handleSubmit
  const initialValues = [
    { value: "skip", name: `${t("Skip")}` },
    { value: "customerFirstName", name: `${t("Customer First Name")}` },
    { value: "customerLastName", name: `${t("Customer Last Name")}` },
    { value: "customerLastRuby", name: `${t("Customer Last Ruby")}` },
    { value: "customerFirstRuby", name: `${t("Customer First Ruby")}` },
    { value: "customerSex", name: `${t("Gender")}` },
    { value: "customerBusinessType", name: `${t("Profession")}` },
    { value: "customerZipCode", name: `${t("Customer Zip Code")}` },
    { value: "customerEmail", name: `${t("Customer Email")}` },
    { value: "storeUsed", name: `${t("Stores Used")}` },
    { value: "householdIncome", name: `${t("Household Income")}` },
    { value: "customerMaritalStatus", name: `${t("Customer Marital Status")}` },
    { value: "customerChildren", name: `${t("Customer Children")}` },
    { value: "contentsOfConsultation", name: `${t("Contents Of Consultation")}` },
    { value: "preferredContactTime", name: `${t("Preferred Contact Time")}` },
    { value: "customerFirstUserId", name: `${t("Customer First User Id")}` },
    { value: "customerSecondUserId", name: `${t("Customer Second User Id")}` },
    { value: "customerMemo1", name: `${t("Customer Memo 1")}` },
    { value: "customerMemo2", name: `${t("Customer Memo 2")}` },
    { value: "customerAddress1", name: `${t("Customer Address 1")}` },
    { value: "customerAddress2", name: `${t("Customer Address 2")}` },
    { value: "customerAddress3", name: `${t("Customer Address 3")}` },
    { value: "customerAddress4", name: `${t("Customer Address 4")}` },
    { value: "customerBirthday", name: `${t("Customer Birthday")}` },
    { value: "customerAge", name: `${t("Customer Age")}` },
    { value: "telephone1", name: `${t("Telephone 1")}` },
    { value: "telephone2", name: `${t("Telephone 2")}` },
    { value: "telephone3", name: `${t("Telephone 3")}` },
    { value: "telephone4", name: `${t("Telephone 4")}` },
    { value: "telephone5", name: `${t("Telephone 5")}` },
    { value: "telephone6", name: `${t("Telephone 6")}` },
    { value: "telephone7", name: `${t("Telephone 7")}` },
  ];

  const [optionList, setOptionList] = useState(initialValues);

  const FormObserver = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      // console.log(values.groupId);
      setgroupId(values.groupId || "");
    }, [values.groupId]);
    return null;
  };

  useEffect(() => {
    async function getOptionList() {
      if (groupId) {
        const response = await axiosFetch.get(
          `/categoryItemsByGroupId/${groupId}`
        );
        if ((response.status = 200)) {
          let temp = [];
          response.data.map((data) => {
            temp.push({
              value: data.category.categoryId + "_" + data.itemId,
              name: data.category.categoryName + " -> " + data.itemName,
            });
          });
          console.log(response.data, "categoryItems by Group Id");
          props.setCategoryItemsArray(response.data);
          setOptionList(initialValues.concat(temp));
          // console.log(optionList);
        } else {
          console.log("Response status not 200 in Display Component Import");
        }
      }
    }
    getOptionList();
  }, [groupId]);

  return (
    <div>
      <FormObserver />
      <table className="table">
        <thead>
          <tr>
            {data[0].map((item1, index1) => (
              <th scope="col" key={index1 + "TH"}>
                <CustomSelect
                  data={{
                    name: `Column.${index1}`,
                    label: `${t("Select Item")}`,
                    list: optionList,
                  }}
                  // style={{width:"70px"}}

                  defaultValue=""
                  whitespaceflag="true"
                  // value='skip'
                  required
                ></CustomSelect>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index + "TR"}
              style={{
                width: "70px",
              }}
            >
              {item.map((item1, index1) => (
                <td key={index1}>{item1}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Display;
